<template>
  <div>
    <section class="encabezado encuesta-medicina row-start">
      <h2>Padres UFV</h2>
    </section>
    <section class="contenedor-encuesta row-between" id="encuesta">
      <h2>Formulario de inscripción</h2>
      <legend>Datos personales</legend>
      <ValidationObserver ref="form" v-slot="{ failed, valid }">
        <form
          class="row-between fww paso"
          @submit.prevent="validate"
          :valid="valid"
          autocomplete="off"
        >
          <div class="contenedor-input half">
            <ValidationProvider
              rules="required|min:3"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="name"
                placeholder="Nombre*"
                v-model="data.name"
                :class="classes"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required|min:3"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="surname"
                placeholder="Primer apellido*"
                v-model="data.surname"
                :class="classes"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <input
              autocomplete="off"
              type="text"
              name="surname_2"
              placeholder="Segundo apellido"
              v-model="data.surname_2"
            />
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required|min:9"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="phone"
                placeholder="Teléfono/móvil*"
                v-model="data.phone"
                :class="classes"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <select
                id="document_type"
                name="document_type"
                v-model="data.document_type"
                :class="classes"
              >
                <option value="">Tipo de documento*</option>
                <option
                  v-for="(tipo, key) in dominios.tiposdocumento"
                  :key="key"
                  :value="key"
                >
                  {{ tipo }}
                </option>
              </select>
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required|min:9"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="document"
                placeholder="Documento*"
                v-model="data.document"
                :class="classes"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <h5>Sexo</h5>
              <input
                type="radio"
                :value="'0'"
                name="sexo"
                id="hombre"
                v-model="data.gender"
              />
              <label :class="classes" for="hombre">Hombre</label>
              <input
                type="radio"
                :value="'1'"
                name="sexo"
                id="mujer"
                v-model="data.gender"
              />
              <label :class="classes" for="mujer">Mujer</label>
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="contenedor-input half">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="email"
                placeholder="Correo electrónico*"
                :class="classes"
                v-model="data.email"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="contenedor-input">
            <ValidationProvider
              rules="required|min:3"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                autocomplete="off"
                type="text"
                name="address"
                placeholder="Dirección completa*"
                :class="classes"
                v-model="data.address"
              />
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <legend>Situación profesional</legend>
          <div class="contenedor-input">
            <input
              type="radio"
              :value="0"
              name="pro-situation"
              id="idle"
              v-model="data.working"
            />
            <label for="idle">Inactivo</label>
            <input
              type="radio"
              :value="1"
              name="pro-situation"
              id="asset"
              v-model="data.working"
            />
            <label for="asset">Activo</label
            >
          </div>

          <template v-if="data.working == '1'">
            <div class="contenedor-input half">
                <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                    <input autocomplete="new-password" type="text" name="puesto" placeholder="Puesto*" v-model="data.profesional[0].position" :class="classes" max="50">
                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input half">
                <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                    <input autocomplete="new-password" type="text" name="empresa" placeholder="Empresa*" v-model="data.profesional[0].company" :class="classes" max="50">
                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input half">
                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                    <date-picker :name="'fecha'" v-model="data.profesional[0].start_date" :format="'DD/MM/YYYY'" :value-type="'YYYY/MM/DD'" :class="classes" :placeholder="'Fecha de Inicio*'"></date-picker>
                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input half">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <autocomplete :class="classes" :name="'sector'" :label="'Sector*'" :type="'sector'" v-model="data.profesional[0].sector" :idkey="'id'" :formtype="'select'"></autocomplete>
                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </template>

          <legend>Datos hijo/s</legend>
          <div class="contenedor-input -check">
            <input
              type="checkbox"
              id="no-hijos"
              v-model="notsons"
              name="nohijos"
            />
            <label for="no-hijos"
              >No tengo hijos que hayan estudiado en la UFV</label
            >
          </div>

          <template v-if="notsons">
            <div class="contenedor-input half">
              <ValidationProvider
                rules="required"
                v-slot="{ errors, ariaInput, ariaMsg, classes }"
              >
                <select
                  id="family_type"
                  name="family_type"
                  v-model="data.family[0].type"
                  :class="classes"
                >
                  <option value="">Selecciona tu relación con UFV</option>
                  <option value="5">Soy PAS</option>
                  <option value="6">Soy PROF</option>
                  <option value="1">Soy Amigo</option>
                  <option value="9">Soy Club Sénior</option>
                </select>
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </template>

          <template v-if="!notsons">
            <div v-for="(child, key) in data.family" :key="key" :value="key">
              <h4>Hijo {{ key + 1 }}</h4>
              <div class="row-between fww">
                <div class="contenedor-input half">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, ariaInput, ariaMsg, classes }"
                  >
                    <input
                      autocomplete="off"
                      type="text"
                      name="name"
                      placeholder="Nombre*"
                      v-model="child.name"
                      :class="classes"
                    />
                  </ValidationProvider>
                </div>
                <div class="contenedor-input half">
                  <ValidationProvider
                    rules="min:3"
                    v-slot="{ errors, ariaInput, ariaMsg, classes }"
                  >
                    <input
                      autocomplete="off"
                      type="number"
                      name="name"
                      placeholder="Año de Ingreso"
                      v-model="child.start_ufv_year"
                      :class="classes"
                    />
                    <span v-bind="ariaMsg" class="custom-error">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
                <div class="contenedor-input">
                  <ValidationProvider
                    v-slot="{ errors, ariaInput, ariaMsg, classes }"
                  >
                    <autocomplete
                      :class="classes"
                      :name="'study' + key"
                      :label="'Estudios que realiza en la UFV'"
                      :type="'study'"
                      v-model="child.studies"
                      :idkey="'id'"
                      :formtype="'select'"
                    ></autocomplete>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <a @click="addChild" class="action-btn">Añadir más hijos/as</a>
          </template>

          <div class="contenedor-input -check">
            <ValidationProvider
              rules="required"
              v-slot="{ errors, ariaInput, ariaMsg, classes }"
            >
              <input
                type="checkbox"
                id="id"
                :class="classes"
                v-model="data.politica"
                name="politica"
              />
              <label for="id">
                He leído y acepto la
                <a
                  href="https://www.alumni-ufv.es/es/general/politica-privacidad"
                  target="_blank"
                >
                  Política de privacidad
                </a>
              </label>
              <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <a @click="validate" class="boton-azul">Enviar</a>
        </form>
      </ValidationObserver>

      <div class="form-popup-encuesta" :show="!!dialog">
        <div class="popup" v-if="dialog">
          <h2>{{ dialog.titulo }}</h2>
          <p>{{ dialog.cuerpo }}</p>

          <a
            v-if="dialog.btnPadres"
            href="https://padres-ufv.es/"
            class="boton-azul"
            >Ir a Padres UFV</a
          >
          <a v-else @click="dialog = null" class="boton-azul">Aceptar</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es/es';

export default {
  name: "formulario-padres",
  computed: {
    ...mapGetters({
      dominios: "getDominios",
    }),
  },
  mounted() {
    console.log(this.$route.query.event_id);
    this.data.event_id = this.$route.query.event_id;
  },
  data: () => ({
    data: {
      name: "",
      surname: "",
      surname_2: "",
      gender: 0,
      working: 0,
      email: "",
      phone: "",
      address: "",
      document: "",
      document_type: "",
      event_id: "",
      family: [
        {
          name: "",
          type: 4,
          studies: [],
          comment: "Alta desde Registro Padres UFV",
        },
      ],
      profesional: [
        {
          company: '',
          position: '',
          start_date: '',
          sector: ''
        }
      ]
    },
    defaultChild: {
      name: "",
      type: 4,
      studies: [],
      comment: "Alta desde Registro Padres UFV",
    },
    other_bowl: null,
    error: null,
    clicked: false,
    notsons: false,
    dialog: null,
  }),
  methods: {
    validate() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.data.familiar_bowl = true;
          if (this.data.working == 0 ) {
            delete this.data.profesional
          }
          axios
            .post("/api/auth/register", this.data)
            .then((response) => {
              console.log(response);
              this.printDialog(response);
              this.$refs.form.reset();
            })
            .catch((response) => {
              this.printDialog(response.response);
            });
        } else {
          const invalidField = this.$refs.form.$children.find((e) => !e.valid)
          if (invalidField)
            invalidField.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
        }
      });
    },
    printDialog(response) {
      if (response == null) {
        this.dialog = {
          titulo: "Error General",
          cuerpo: "Por favor contacte con soporte",
        };
      } else if (response.data && response.data.status) {
        this.dialog = {
          titulo: "¡Enhorabuena!",
          cuerpo: "Te has registrado correctamente.",
          btnPadres: true,
        };
        this.reset();
      } else if (response.data.messages) {
        this.dialog = {
          titulo: "Error",
          cuerpo: response.data.messages[0].message,
        };
      }
    },
    reset() {
      this.clicked = false;
      this.data = {
        name: "",
        surname: "",
        surname_2: "",
        gender: 0,
        email: "",
        phone: "",
        address: "",
        document: "",
        document_type: "",
        family: [],
        profesional: [{}]
      };
    },
    reload() {
      location.reload();
    },
    addChild() {
      this.data.family.push({ ...this.defaultChild });
    },
  },
  components: { DatePicker },
  watch: {
    notsons: function (newValue) {
      if (newValue == false) {
        this.data.family = [{ ...this.defaultChild }];
      } else {
        this.data.family = [{ ...this.defaultChild }];
        this.data.family[0].type = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contenedor-input input[type="checkbox"] + label,
.contenedor-input input[type="radio"] + label {
  width: auto;
  margin-right: 50px;
  margin-bottom: 10px;
}

.contenedor-input input[type="checkbox"],
.contenedor-input input[type="radio"] {
  pointer-events: none;
}

.contenedor-input.-check {
  margin-top: 30px;
  margin-bottom: 30px;
}

.contenedor-input select {
  width: 100%;
}

.action-btn {
  width: 100%;
  display: inline-block;
  text-decoration: underline;
  color: #003466;
  margin-bottom: 20px;
}

.action-btn.-red {
  color: red;
}

h4 {
  width: 100%;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
}

.responsive {
  display: none;
}

.paso input {
  padding-left: 0 !important;
}

@media screen and (max-width: 895px) {
  .responsive {
    display: block;
  }

  .pc {
    display: none;
  }
}
</style>
